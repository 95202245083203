<template>
  <div>
      <v-main>
        <v-container fluid>
            <v-card>
              <v-card-title>
                Data Jenis Simpanan
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Cari Simpanan"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="savings"
                :search="search"
              >
                <template v-slot:top>
                    <v-toolbar flat>
                        <!-- <v-spacer /> -->
                        <v-dialog
                          v-model="dialog"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              class="mb-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="addItem()"
                            >
                              Tambah Simpanan
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{formTitle}}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row dense>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      v-model="editedItem.simpananName"
                                      label="Jenis Simpanan"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      v-model="editedItem.simpananAmount"
                                      label="Besaran Simpanan"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-radio-group v-model="editedItem.cashWithdrawal" row label="Opsi Tarik">
                                      <v-radio label="Dapat ditarik" value="T"></v-radio>
                                      <v-radio label="Tidak dapat ditarik" value="F"></v-radio>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                    v-if="editedItem.cashWithdrawal==='T'"
                                  >
                                    <v-text-field
                                      v-model="editedItem.chargeAmount"
                                      label="Biaya tarik"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="red darken-1"
                                text
                                @click="close"
                              >
                                Batal
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.cashWithdrawal`]="{item}">
                    {{item.cashWithdrawal==='T'?'Tarik Tunai':'-'}}
                </template>
                <template v-slot:[`item.chargeAmount`]="{item}">
                    {{item.chargeAmount===null?'0':item.chargeAmount}}
                </template>
                <template v-slot:[`item.actions1`]="{item}">
                  <v-switch
                      hide-details
                      v-model="item.activeStatus"
                      :label="item.activeStatus?'Aktif':'Tidak Aktif'"
                      @change="onChangeStatus(item)">

                    </v-switch>
                </template>
                <template v-slot:[`item.actions2`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    color="green"
                  >
                    mdi-pencil
                  </v-icon>
                  <!-- <v-icon
                    small
                    @click="deleteItem(item)"
                    color="red"
                    disabled
                  >
                    mdi-delete
                  </v-icon> -->
                </template>
              </v-data-table>
            </v-card>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{msgSnack}} 
            </v-snackbar>
        </v-container>
      </v-main>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </div>
  
</template>

<script>
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
  components: {
    OverlayProgress
  },
  data() {
    return {
        snackbar: false,
        timeout: 4000,
        msgSnack: '',
        dialog: false,
        editedIndex: -1,
        editedItem: {
          simpananCode: null,
          simpananId: null,
          simpananName: null,
          simpananAmount: null,
          cashWithdrawal: null,
          chargeAmount: null,
          activeStatus: null,
        },
        defaultItem: {
          simpananCode: null,
          simpananId: null,
          simpananName: null,
          simpananAmount: null,
          cashWithdrawal: null,
          chargeAmount: null,
          activeStatus: true,
        },
        search: '',
        headers: [
          {
            text: 'Jenis Simpanan',
            align: 'start',
            sortable: true,
            value: 'simpananName',
          },
          { text: 'Besaran Simpanan', value: 'simpananAmount' },
          { text: 'Opsi Tarik', value: 'cashWithdrawal' },
          { text: 'Biaya Tarik', value: 'chargeAmount' },
          { text: 'Status', value: 'actions1', sortable: false },
          { text: '', value: 'actions2', sortable: false },
        ],
        savings: [],
    }
  },
  computed:{
    formTitle(){
      return this.editedIndex === -1 ? 'Tambah Simpanan' : 'Edit Simpanan'
    }
  },
  watch:{
    'editedItem.simpananName' : function() {
      if(this.editedItem.simpananName !== null && this.editedItem.simpananName.length===1){
        this.editedItem.simpananName = 'Simpanan ' + this.editedItem.simpananName
      }
    }
  },
  methods:{
    onChangeStatus(item) {
      // console.log(JSON.stringify(item))
      var url = this.$root.api.post.simpanan + '?idSimpanan=' + item.simpananId

      this.$root.api.sentAPI(
      {
          method: 'post',
          api: url,
          form: {
              name: item.simpananName,
              amount: item.simpananAmount,
              cashWithdrawal: item.cashWithdrawal,
              chargeAmount: item.chargeAmount,
              activeStatus: item.activeStatus
          }
      }).then(() => {})
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false
    },
    addItem(){
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
    },
    editItem(item){
      // console.log('edit ' + JSON.stringify(item))
      this.editedItem = Object.assign({}, item)
      this.editedIndex = this.savings.indexOf(item)
      this.editedItem.chargeAmount = this.editedItem.chargeAmount==0?null:this.editedItem.chargeAmount
      this.dialog = true
    },
    deleteItem(item){
      console.log('delete ' + JSON.stringify(item))
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
      // console.log(JSON.stringify(this.editedItem.simpananAmount))
      // console.log(JSON.stringify(this.savings))
    },
    save() {
      // console.log(JSON.stringify(this.editedItem.simpananAmount))
      var url
      url = this.$root.api.post.simpanan
      if(this.editedIndex > -1)
        url = this.$root.api.post.simpanan + '?idSimpanan=' + this.editedItem.simpananId

      if(this.editedItem.simpananAmount === null || this.editedItem.simpananAmount.length === 0)
        this.editedItem.simpananAmount = 0

      if (this.editedItem.simpananName === null) {
        this.snackbar = true
        this.msgSnack = 'Jenis simpanan tidak boleh kosong'
        return
      }

      if(this.editedItem.cashWithdrawal==='F') {
        this.editedItem.chargeAmount = 0
      }

      this.$root.api.sentAPI(
      {
          method: 'post',
          api: url,
          form: {
              name: this.editedItem.simpananName,
              amount: JSON.parse("["+this.editedItem.simpananAmount+"]"),
              cashWithdrawal: this.editedItem.cashWithdrawal,
              chargeAmount: this.editedItem.chargeAmount,
          }
      }).then((res) => {
          // console.log(JSON.stringify(res))
          if(res.status.status === 'Success'){
            if (this.editedIndex > -1) {
              Object.assign(this.savings[this.editedIndex], this.editedItem)
            } else {
              this.savings.push(this.editedItem)
            }
            this.close()
          }
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false
    },
    init() {
      this.$root.api.sentAPI(
        {
            method: 'get',
            api: this.$root.api.get.simpanan,
        }).then((res) => {
            // this.savings = res.data
            // console.log(JSON.stringify(res))
            for (let i = 0; i < res.data.length; i++) {
              this.savings.push({
                simpananCode: res.data[i].simpananCode,
                simpananId: res.data[i].simpananId,
                simpananName: this.$root.capitalFirstChart(res.data[i].simpananName),
                simpananAmount: res.data[i].simpananAmount,
                cashWithdrawal: res.data[i].cashWithdrawal,
                chargeAmount: res.data[i].chargeAmount,
                activeStatus: res.data[i].activeStatus,
              })
            }
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
        if(!this.$root.isError) this.$root.overlay = false
        // this.$nextTick(function () {this.$root.overlay = false})
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style scoped>
</style>
