var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Data Jenis Simpanan "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari Simpanan","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.savings,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.addItem()}}},'v-btn',attrs,false),on),[_vm._v(" Tambah Simpanan ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Jenis Simpanan"},model:{value:(_vm.editedItem.simpananName),callback:function ($$v) {_vm.$set(_vm.editedItem, "simpananName", $$v)},expression:"editedItem.simpananName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Besaran Simpanan"},model:{value:(_vm.editedItem.simpananAmount),callback:function ($$v) {_vm.$set(_vm.editedItem, "simpananAmount", $$v)},expression:"editedItem.simpananAmount"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-radio-group',{attrs:{"row":"","label":"Opsi Tarik"},model:{value:(_vm.editedItem.cashWithdrawal),callback:function ($$v) {_vm.$set(_vm.editedItem, "cashWithdrawal", $$v)},expression:"editedItem.cashWithdrawal"}},[_c('v-radio',{attrs:{"label":"Dapat ditarik","value":"T"}}),_c('v-radio',{attrs:{"label":"Tidak dapat ditarik","value":"F"}})],1)],1),(_vm.editedItem.cashWithdrawal==='T')?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Biaya tarik"},model:{value:(_vm.editedItem.chargeAmount),callback:function ($$v) {_vm.$set(_vm.editedItem, "chargeAmount", $$v)},expression:"editedItem.chargeAmount"}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1)]},proxy:true},{key:"item.cashWithdrawal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cashWithdrawal==='T'?'Tarik Tunai':'-')+" ")]}},{key:"item.chargeAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chargeAmount===null?'0':item.chargeAmount)+" ")]}},{key:"item.actions1",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"hide-details":"","label":item.activeStatus?'Aktif':'Tidak Aktif'},on:{"change":function($event){return _vm.onChangeStatus(item)}},model:{value:(item.activeStatus),callback:function ($$v) {_vm.$set(item, "activeStatus", $$v)},expression:"item.activeStatus"}})]}},{key:"item.actions2",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.msgSnack)+" ")])],1)],1),_c('OverlayProgress',{attrs:{"overlay":_vm.$root.overlay,"isError":_vm.$root.isError}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }